import { decamelize } from 'humps';
import BravelyData from 'utils/data';
import BravelyFeature from 'utils/feature';
import { isEmployee, isPro } from 'utils/role';
import iconCheckIn from './svg/icon-check-in.svg';
import iconIncomplete from './svg/icon-incomplete.svg';
import iconSeeMore from './svg/icon-more.svg';
import iconUpcoming from './svg/icon-upcoming.svg';
const scssVars = require('./variables.scss');

/**
 * Flags
 */
export const showEmployeeDashboard =
  isEmployee() && BravelyData('new_employee_dashboard');
export const coursesProExperienceEnabled = BravelyFeature(
  'courses_pro_experience'
);
export const proContentHubEnabled = BravelyFeature('pro_content_hub');
export const basePath = isPro() ? '/v2' : '';

/**
 * Misc Static Values
 */
export const APP_NAME = 'proApp';
export const APP_CONTAINER_ID = '#container--pro-app';
export const PANE_POSITION_LEFT = 'left';
export const PANE_POSITION_TOP = 'top';
export const PANE_POSITION_RIGHT = 'right';
export const PRO_APP_IFRAME_PARAM = 'is_pro_app';
export const KEYCODE_ESC = 27;
export const REGEX_ENCODED_ID = '[0-9a-z]{6}';
export const API_DEFAULT_PER_PAGE = 10;
export const API_DEFAULT_CONSULT_INCLUDE = [
  'employee',
  'pro',
  'coach',
  'coachee',
  'organization',
];
export const API_DEFAULT_PARAMS = {
  perPage: API_DEFAULT_PER_PAGE,
  include: API_DEFAULT_CONSULT_INCLUDE,
};
/**
 * SCSS Variables
 */
export const SCSS_VARS = scssVars; // Variables defined and exported from SCSS
export const ANIMATION_TIMING = SCSS_VARS.timing;

/**
 * [ON_MOUNT] is a helper constant to put human words to the
 * useEffect behavior executed when a component is "mounted"
 * for the first time.
 */
export const ON_MOUNT = [];
export const NOOP_FN = () => {};
/**
 * Drawers
 */
export const DRAWER_CHECK_IN = 'check-in';
export const DRAWER_ERROR = 'error';
export const DRAWER_BOOKING = 'booking';
export const DRAWER_RESCHEDULE = 'reschedule';
export const DRAWER_SESSION = 'session';
export const DRAWER_ADVANCE_JOURNEY = 'advance-journey';
export const DRAWER_NAMES = [
  DRAWER_BOOKING,
  DRAWER_RESCHEDULE,
  DRAWER_CHECK_IN,
  DRAWER_SESSION,
  DRAWER_ERROR,
  DRAWER_ADVANCE_JOURNEY,
];
const DRAWER_NAMES_PATH_PARAM = DRAWER_NAMES.join('|'); // "drawer|drawer|drawer"

/**
 * Workspaces
 */
export const WORKSPACE_ACCOUNT = 'account';
export const WORKSPACE_EMPLOYEE_ACCOUNT = 'account';
export const WORKSPACE_AVAILABILITY = 'availability';
export const WORKSPACE_TODOS = 'todos';
export const WORKSPACE_LIBRARY = 'library';
export const WORKSPACE_REFER_COWORKER = 'refer';
export const WORKSPACE_CHECK_INS = 'check-ins';
export const WORKSPACE_DASHBOARD = 'dashboard';
export const WORKSPACE_PAYMENTS = 'payments';
export const WORKSPACE_SESSIONS = 'sessions';
export const WORKSPACE_EMPLOYEE_SESSIONS = 'sessions';
export const WORKSPACE_EMPLOYEE_WEBINAR = 'webinars';
export const WORKSPACE_EMPLOYEE_COURSES = 'courses';
export const WORKSPACE_EMPLOYEE_DASHBOARD = 'dashboard';
export const WORKSPACE_SURVEY = 'session';
export const WORKSPACE_BOOKING = 'book';
export const WORKSPACE_RESCHEDULE = 'reschedule';
export const WORKSPACE_SUMMARY = 'summary';
export const WORKSPACE_CHECK_IN = 'check-in';
export const WORKSPACE_CHECK_IN_SUMMARY = 'check-in-summary';
export const WORKSPACE_NOT_FOUND = 'notFound';
export const WORKSPACE_ERROR = 'error';
export const WORKSPACE_NEEDS_FRAMEWORK = 'needs-and-demography';
export const WORKSPACE_BRAVELY_ADVANCE_SURVEY = 'assessments';
export const WORKSPACE_COACHING_ASSISTANT = 'coaching-companion';
export const WORKSPACE_NAMES = [
  WORKSPACE_ACCOUNT,
  WORKSPACE_AVAILABILITY,
  WORKSPACE_CHECK_INS,
  WORKSPACE_DASHBOARD,
  WORKSPACE_PAYMENTS,
  WORKSPACE_LIBRARY,
  WORKSPACE_BOOKING,
  WORKSPACE_SESSIONS,
  WORKSPACE_TODOS,
  WORKSPACE_REFER_COWORKER,
  WORKSPACE_SUMMARY,
  WORKSPACE_CHECK_IN,
  WORKSPACE_CHECK_IN_SUMMARY,
  WORKSPACE_NOT_FOUND,
  WORKSPACE_ERROR,
  WORKSPACE_RESCHEDULE,
  WORKSPACE_NEEDS_FRAMEWORK,
  WORKSPACE_EMPLOYEE_WEBINAR,
  WORKSPACE_SURVEY,
  WORKSPACE_EMPLOYEE_COURSES,
  WORKSPACE_BRAVELY_ADVANCE_SURVEY,
  WORKSPACE_COACHING_ASSISTANT,
];
const WORKSPACE_NAMES_PATH_PARAM = WORKSPACE_NAMES.join('|'); // "workspace|workspace|workspace"

/**
 * Sub-Workspaces
 */
export const SUB_WORKSPACE_COURSES_PRECOURSE = 'pre-course-survey';
export const SUB_WORKSPACE_COURSES_POSTCOURSE = 'post-course-survey';
export const SUB_WORKSPACE_SESSIONS_UPCOMING = 'upcoming';
export const SUB_WORKSPACE_SESSIONS_PAST = 'past';
export const SUB_WORKSPACE_SESSIONS_INCOMPLETE = 'incomplete';
export const SUB_WORKSPACE_SESSIONS_CHECKINS = 'check-ins';
export const SUB_WORKSPACE_COURSES_POSTPROGRAM = 'post-program-survey';
export const SUB_WORKSPACE_NAMES = [
  SUB_WORKSPACE_SESSIONS_UPCOMING,
  SUB_WORKSPACE_SESSIONS_PAST,
  SUB_WORKSPACE_SESSIONS_INCOMPLETE,
  SUB_WORKSPACE_SESSIONS_CHECKINS,
];

/**
 * Routes
 */
export const PATH_ID = `:id(${REGEX_ENCODED_ID})`;
export const PATH_PAGE = 'page/:pageNum';
export const PATH_DRAWER_WITHOUT_ID = `/:workspaceName/drawer/:drawerName(${DRAWER_NAMES_PATH_PARAM})`;
export const PATH_DRAWER = `/:workspaceName/drawer/:drawerName(${DRAWER_NAMES_PATH_PARAM})/${PATH_ID}`;
export const PATH_DRAWER_WITH_PAGES = `/:workspaceName/:workspaceBody/${PATH_PAGE}/drawer/:drawerName(${DRAWER_NAMES_PATH_PARAM})/${PATH_ID}`;
export const PATH_DRAWER_WITHOUT_PAGES = `/:workspaceName/:workspaceBody/drawer/:drawerName(${DRAWER_NAMES_PATH_PARAM})/${PATH_ID}`;
export const PATH_HELP = '#help';
export const PATH_LOGOUT = '#logout';
export const PATH_ROOT = '/';
export const PATH_WORKSPACE_WILDCARD = `/*`;
export const PATH_WORKSPACE = `/:workspaceName(${WORKSPACE_NAMES_PATH_PARAM})/:workspaceBody?`;
export const PATH_WORKSPACE_BODY_PAGED = `/:workspaceName(${WORKSPACE_NAMES_PATH_PARAM})/:workspaceBody?/${PATH_PAGE}`;
export const PATH_WORKSPACE_ACCOUNT = `/${WORKSPACE_ACCOUNT}`;
export const PATH_WORKSPACE_AVAILABILITY = `/${WORKSPACE_AVAILABILITY}`;
export const PATH_WORKSPACE_DASHBOARD = `/${WORKSPACE_DASHBOARD}`;
export const PATH_WORKSPACE_LIBRARY = `/${WORKSPACE_LIBRARY}`;
export const PATH_WORKSPACE_TODOS = `/${WORKSPACE_TODOS}`;
export const PATH_WORKSPACE_PAYMENTS = `/${WORKSPACE_PAYMENTS}`;
export const PATH_WORKSPACE_SESSIONS = `/${WORKSPACE_SESSIONS}`;
export const PATH_WORKSPACE_COACHING_ASSISTANT = `/${WORKSPACE_COACHING_ASSISTANT}`;
export const PATH_WORKSPACE_BOOKING = `/${WORKSPACE_BOOKING}`;
export const PATH_WORKSPACE_WEBINAR = `/${WORKSPACE_EMPLOYEE_WEBINAR}`;
export const PATH_WORKSPACE_SURVEY = `/${WORKSPACE_SURVEY}`;
export const PATH_WORKSPACE_RESCHEDULE = `/${WORKSPACE_RESCHEDULE}`;
export const PATH_WORKSPACE_NEEDS_FRAMEWORK = `/${WORKSPACE_NEEDS_FRAMEWORK}`;
export const PATH_WORKSPACE_COURSES = `/${WORKSPACE_EMPLOYEE_COURSES}`;
export const PATH_EMPLOYEE_WORKSPACE_SESSIONS = `/${WORKSPACE_SESSIONS}`;
export const PATH_WORKSPACE_SESSIONS_UPCOMING = `/${WORKSPACE_SESSIONS}/upcoming`;
export const PATH_WORKSPACE_SESSIONS_PAST = `/${WORKSPACE_SESSIONS}/past`;
export const PATH_WORKSPACE_SESSIONS_DETAIL = `/${WORKSPACE_SESSIONS}/:id`;
export const PATH_WORKSPACE_SESSIONS_INCOMPLETE = `/${WORKSPACE_SESSIONS}/incomplete`;
export const PATH_WORKSPACE_SESSIONS_CHECKINS = `/${WORKSPACE_SESSIONS}/${decamelize(
  WORKSPACE_CHECK_INS,
  {
    separator: '-',
  }
)}`;
export const PATH_WORKSPACE_SUMMARY = `/${WORKSPACE_SUMMARY}/${PATH_ID}`;
export const PATH_WORKSPACE_CHECK_IN = `/${decamelize(WORKSPACE_CHECK_IN, {
  separator: '-',
})}/${PATH_ID}`;
export const PATH_WORKSPACE_CHECK_IN_SUMMARY = `/${WORKSPACE_CHECK_IN_SUMMARY}/${PATH_ID}`;
export const PATH_WORKSPACE_ERROR = `/${WORKSPACE_ERROR}`;
export const PATH_WORKSPACE_NOT_FOUND = `/${decamelize(WORKSPACE_NOT_FOUND, {
  separator: '-',
})}`;
export const PATH_WORKSPACE_BRAVELY_ADVANCE_SURVEY = `/${WORKSPACE_BRAVELY_ADVANCE_SURVEY}`;

/**
 * Map JSONAPI payload `type:''` to App state namespace
 * Note: this should match the mappings in app/services/api_service.rb
 */
export const API_TYPE_TO_APP_KEY = {
  consult: 'sessions',
  pro: 'pros',
  employee: 'employees',
  organization: 'organizations',
  user: 'users',
  message: 'messages',
  todo: 'todos',
  course: 'courses',
};

/**
 * Page Titles
 */
export const PAGE_TITLE_WORKSPACE_ROOT = isEmployee()
  ? 'Bravely'
  : 'Bravely Pro';
export const PAGE_TITLE_WORKSPACE_DASHBOARD = 'Dashboard';
export const PAGE_TITLE_WORKSPACE_TODOS = "To-do's";
export const PAGE_TITLE_WORKSPACE_LIBRARY = 'Library';
export const PAGE_TITLE_WORKSPACE_REFER_COWORKER = 'Refer a Co-Worker';
export const PAGE_TITLE_WORKSPACE_SESSIONS = 'Sessions';
export const PAGE_TITLE_WORKSPACE_WEBINAR = 'Webinar';
export const PAGE_TITLE_WORKSPACE_AVAILABILITY = 'Availability';
export const PAGE_TITLE_WORKSPACE_PAYMENTS = 'Payments';
export const PAGE_TITLE_WORKSPACE_ACCOUNT = 'Account';
export const PAGE_TITLE_WORKSPACE_ACCOUNT_WEBVIEW = 'Account Details';
export const PAGE_TITLE_WORKSPACE_HELP = 'Help';
export const PAGE_TITLE_WORKSPACE_NOT_FOUND = 'Not Found';
export const PAGE_TITLE_WORKSPACE_ERROR = 'Oops!';
export const PAGE_TITLE_WORKSPACE_SUMMARY = 'Session Summary';
export const PAGE_TITLE_WORKSPACE_BOOKING = 'Book a Session';
export const PAGE_TITLE_WORKSPACE_RESCHEDULE = 'Reschedule Session';
export const PAGE_TITLE_WORKSPACE_SURVEY = 'Survey';
export const PAGE_TITLE_WORKSPACE_ASSESSMENT = 'Assessment';
export const PAGE_TITLE_DRAWER_SESSION = 'Session Preview';
export const PAGE_TITLE_DRAWER_JOURNEY = 'Advance Journey';
export const PAGE_TITLE_DRAWER_CHECK_IN = 'Check-In Preview';
export const PAGE_TITLE_DRAWER_BOOKING = 'Schedule Session';
export const PAGE_TITLE_DRAWER_RESCHEDULE = 'Reschedule Session';
export const PAGE_TITLE_WORKSPACE_NEEDS_FRAMEWORK = 'Needs & Demography';
export const PAGE_TITLE_WORKSPACE_COURSES = 'Courses';
export const PAGE_TITLE_WORKSPACE_COACHING_ASSISTANT = 'Coaching Companion';

/**
 * Reducer Actions
 */
export const APPEND_API_DATA = 'APPEND_API_DATA';
export const APPEND_API_DATA_WITH_ORDERED_IDS =
  'APPEND_API_DATA_WITH_ORDERED_IDS';
export const REPLACE_ORDERED_DATA = 'REPLACE_ORDERED_DATA';
export const REDIRECT = 'REDIRECT';
export const SET_ACTIVE_ITEM_ID = 'SET_ACTIVE_ITEM_ID';
export const SET_CURRENT_WORKSPACE = 'SET_CURRENT_WORKSPACE';
export const SET_DRAWER_IS_OPEN = 'SET_DRAWER_IS_OPEN';
export const SET_MOBILE_NAV_IS_OPEN = 'SET_MOBILE_NAV_IS_OPEN';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const ACTIONS = {
  APPEND_API_DATA,
  APPEND_API_DATA_WITH_ORDERED_IDS,
  REPLACE_ORDERED_DATA,
  REDIRECT,
  SET_ACTIVE_ITEM_ID,
  SET_CURRENT_WORKSPACE,
  SET_DRAWER_IS_OPEN,
  SET_MOBILE_NAV_IS_OPEN,
  SET_PAGE_TITLE,
};

/**
 * Labels
 */
export const LABEL_CHECKINS_SESSIONS = 'Check-Ins';
export const LABEL_INCOMPLETE_SESSIONS = 'Incomplete Session Summaries';
export const LABEL_PAST_SESSIONS = 'Past Sessions';
export const LABEL_UPCOMING_SESSIONS = 'Upcoming Sessions';
export const LABEL_UPCOMING_SESSIONS_FOOTER =
  'See all of your Upcoming Sessions';
export const LABEL_PAST_SESSIONS_FOOTER = 'See all of your Past Sessions';
export const LABEL_INCOMPLETE_SESSIONS_FOOTER =
  'See all of your Incomplete Sessions';
export const LABEL_INCOMPLETE_TASKS = 'Incomplete Tasks';

/**
 * Icons
 */
export const ICON_CHECKINS = iconCheckIn;
export const ICON_INCOMPLETE = iconIncomplete;
export const ICON_SESSIONS = iconUpcoming;
export const ICON_SEE_MORE = iconSeeMore;
